
import { Component, Vue, Watch } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { ArticleService, HealthProductService, WechatService, PageService } from "src/services";
import tabbar from "src/components/tabbar";
import router from "src/router";
import globalConfig from "../../config/index";
import BannerPage from "src/views/components/banner/page.vue";
import TitlePage from "src/views/components/title/page.vue";
import NavigationPage from "src/views/components/navigation/page.vue";
import ProductPage from "src/views/components/product/page.vue";
import ArticlePage from "src/views/components/article/page.vue";
import InformationSignboardPage from "src/views/components/informationSignboard/page.vue";
import NotifyPage from "src/views/components/notify/page.vue";
import PrefecturePage from "src/views/components/prefecture/page.vue";
import PersonalPage from "src/views/components/personal/page.vue";
import OrderPage from "src/views/components/order/page.vue";

@Component({
  components: {
    tabbar,
    BannerPage,
    TitlePage,
    NavigationPage,
    ProductPage,
    ArticlePage,
    InformationSignboardPage,
    NotifyPage,
    PrefecturePage,
    PersonalPage,
    OrderPage,
  },
})
export default class HealthProductHome extends Vue {
  /**
   * 菜单列表
   * @protected
   */
  private menuList: Array<any> = [
    {
      componentType: 1,
      icon: "src/assets/icons/banner-icon.png",
      name: "轮播图",
      pageComponent: "BannerPage",
      configComponent: "BannerConfig",
    },
    {
      componentType: 2,
      icon: "src/assets/icons/title-icon.png",
      name: "标题文本",
      pageComponent: "TitlePage",
      configComponent: "TitleConfig",
    },
    {
      componentType: 3,
      icon: "src/assets/icons/navigation-icon.png",
      name: "导航栏",
      pageComponent: "NavigationPage",
      configComponent: "NavigationConfig",
    },
    {
      componentType: 4,
      icon: "src/assets/icons/product-icon.png",
      name: "产品",
      pageComponent: "ProductPage",
      configComponent: "ProductConfig",
    },
    {
      componentType: 5,
      icon: "src/assets/icons/article-icon.png",
      name: "文章",
      pageComponent: "ArticlePage",
      configComponent: "ArticleConfig",
    },
    {
      componentType: 6,
      icon: "/static/icons/notify-icon.png",
      name: "通知",
      pageComponent: "NotifyPage",
      configComponent: "NotifyConfig",
    },
    {
      componentType: 7,
      icon: "/static/icons/informationSignboard-icon.png",
      name: "信息看板",
      pageComponent: "InformationSignboardPage",
      configComponent: "InformationSignboardConfig",
    },
    {
      componentType: 8,
      icon: "/static/icons/prefecture-icon.png",
      name: "专区",
      pageComponent: "PrefecturePage",
      configComponent: "PrefectureConfig",
    },
    {
      componentType: 9,
      icon: "/static/icons/person-icon.png",
      name: "个人信息",
      pageComponent: "PersonalPage",
      configComponent: "PersonalConfig",
    },
    {
      componentType: 10,
      icon: "/static/icons/order-icon.png",
      name: "订单",
      pageComponent: "OrderPage",
      configComponent: "OrderConfig",
    },
  ];

  /**
   * 首页自定义数据
   * @private
   * @returns any
   */
  protected pageData: any = {
    properties: "",
    pageType: null,
    name: "",
    id: null,
    enable: false,
    employerId: null,
    components: [],
  };

  /**
   * 页面样式
   * @private
   * @returns any
   */
  protected pageStyle: any = {};

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("$route", { deep: true, immediate: true })
  protected updateOpen(to: any): void {
    this.getPageData();
  }

  /**
   * 获取首页配置信息
   * @private
   * @returns void
   */
  private async getPageData(): Promise<void> {
    try {
      let { content: result } = await PageService.instance.getPage({ pageType: 2 });
      if (result.data) {
        this.pageData = result.data;
        let components = []; // 转译
        result.data.components.forEach((element) => {
          let json = {
            componentType: element.componentType,
            properties: element.properties,
            icon: "",
            name: "",
            pageComponent: "",
            configComponent: "",
          };
          let temObj = this.menuList.filter((res) => res.componentType == element.componentType)[0];
          json.icon = temObj.icon;
          json.name = temObj.name;
          json.pageComponent = temObj.pageComponent;
          json.configComponent = temObj.configComponent;
          components.push(json);
        });
        this.pageData.components = components;
        this.pageData.employerId = result.data.employerId;
        this.pageData.enable = result.data.enable;
        this.pageData.id = result.data.id;
        this.pageData.name = result.data.name;
        this.pageData.pageType = result.data.pageType;
        this.pageData.properties = result.data.properties;
        this.pageStyle = { "background-color": (result.data.properties && result.data.properties.bgColor) || "" };
        document.title = result.data.properties.navigateTitle || "健康产品";
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 自定义组件获取的消息
   * @private
   * @returns void
   */
  private diyMsg(data): void {
    if (data.type == 404) {
      // 体检报告
      router.push({ name: "reportAppointmentList" });
    }
  }
}
